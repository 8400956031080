import { Typography } from '@mui/material'
import React, { CSSProperties, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
//import { tsbus, eventStlFilesDropped, requestOpenFilepicker } from "./tsbus"

type Dropzone = {
    style?: CSSProperties
    logo?: string
    onHoverText?: string
    accept?: string
    callback: (files: File[]) => void
}
export const Dropzone: React.FC<Dropzone> = ({ callback, style = {}, logo = "", onHoverText = "", accept = "*", children }) => {

    const inputRef = React.useRef(null)

    const onDrop = useCallback(acceptedFiles => {
        // inform 3d editor of new stl files dropped

        if (acceptedFiles.length > 0) callback(acceptedFiles)
    }, [])

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({ accept: accept, onDrop, noClick: true, noKeyboard: true })

    return <div style={style} {...getRootProps()} >
        <input id="inputref" ref={inputRef} {...getInputProps()} />

        {isDragActive &&
            <div id="dropzoneactive" style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 10000,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                background: 'rgba(255,255,255,.5)'
            }}>
                <Typography variant="h3">{onHoverText}</Typography>
                <img style={{ width: '150px', height: '126px', opacity: .5 }} src={logo} />
                delete me comment
            </div>
        }

        {children}
    </div>

}