import { DataPoint, GCodeParser, PointType, HeightIndices, writePointsToArray, LocIndices, ParsedGCode } from './GCodeParser'
import React, { Suspense, useEffect, useState, useRef } from 'react'
import * as THREE from 'three'
import { colorNamesLookup } from './constants'
import axios, { AxiosResponse } from 'axios'
import { Color } from 'three'
import { Box, Stack, Button, Slider } from '@mui/material'
import { Canvas, extend, useFrame, ReactThreeFiber } from '@react-three/fiber'
import _ from 'lodash'
import { useGLTF, Text } from '@react-three/drei'

import { PlasColoredButton } from './PlasUIComponents/PlasColoredButton'
import { useMainStore, DisplayParams, displayParamsTemplate, DisplayMode, DisplayModesEnum } from './Stores/MainStore'
import { readFileAsync } from './utils'
import { Interactive, RayGrab, useXR, useXRFrame, useController } from '@react-three/xr'
import Plasmicslogo from './Models/Plasmicslogo'
import { useSpring, animated } from '@react-spring/three'
import { Flex, Box as ThreeBox } from '@react-three/flex'
import { VRConsole } from './VRConsole'

import { useRecoilState } from 'recoil'

import { Entity, getEntityAtom } from './Recoil/entity'






export interface LineSegment {
    bufferGeometry: THREE.BufferGeometry        //internal precomputed buffer geometry
    heightIndices: HeightIndices
    glocIndices: LocIndices
    materialAttributes: {
        color: Color
        opacity?: number
    }
    visible: boolean    //displayed or not   
    key: string         //key, usually the name
    logc: number        //lines of g code
}

interface SliceTable {
    filterLayer: Function
    removeAllFilters: Function
}
const SliceTable: React.FC<SliceTable> = ({ filterLayer, removeAllFilters }) => {

    const rightController = useController("right")
    const [y, setY] = useState(1)
    const [layerZ, setLayerZ] = useState(0)
    const [squeezed, setSqueezed] = useState(false)
    const [cStart, setCStart] = useState(0)
    const [bStart, setBStart] = useState(0)

    const { controllers } = useXR()

    const debugged = useRef(0)

    useFrame((state) => {
        if (!rightController) {
            return
        }

        /* listen directly onto the controller to cancel the squeeze even if the ray does not hit the plane anymore */
        if (debugged.current === 0) {
            console.log(rightController)
            debugged.current = 1


            rightController.controller.addEventListener("selectend", () => {
                setSqueezed(false)
            })
        }

        if (squeezed) {
            const { grip: controller } = rightController

            const y = bStart + (controller.position.y - cStart)

            const layer = (y - 1) * 100


            setLayerZ(layer)

            if (layer > 0 && layer < 60) {
                filterLayer(layer, layer + 0.4)
            } else {
                filterLayer(0, 100)
            }
            setY(y)
        }
    })

    return <Interactive
        onSelectStart={() => {
            if (!rightController) {
                return
            }

            const { grip: controller } = rightController

            setCStart(controller.position.y)
            setBStart(y)

            setSqueezed(true)
        }}
    >

        <mesh position={[0, y, 0]}>
            <Text rotation={[-Math.PI / 2, 0, 0]} position={[.5, 0, .5]} color="blue" fontSize={.05} anchorX="right" anchorY="bottom">
                z: {_.round(layerZ, 2)}
            </Text>
            <Text rotation={[-Math.PI / 2, 0, 0]} position={[-.5, 0, .5]} color="blue" fontSize={.05} anchorX="left" anchorY="bottom">
                slicing table. grab to slice.
            </Text>
            <boxGeometry args={[1, .01, 1]} />
            <meshStandardMaterial
                wireframe={true}
                color="blue"
            />
        </mesh>
    </Interactive>
}


interface DisplayGCodeRecoil {
    entity: Entity
    drawMyUi: Function
}

export const DisplayGCodeRecoil: React.FC<DisplayGCodeRecoil> = (props) => {
    const { entity, drawMyUi } = props

    const [entityState, setEntityState] = useRecoilState(getEntityAtom({ id: entity.id }))

    const [parsedGCode, setParsedGCode] = useState<ParsedGCode>()

    const [displayParams, setDisplayParams] = useState<DisplayParams>(_.clone(displayParamsTemplate))
    const [currentDisplayModeId, setCurrentDisplayModeId] = useState<DisplayModesEnum>(DisplayModesEnum['3D'])

    const [lineSegments, setLineSegments] = useState<Array<LineSegment>>([])

    const [sliderValue, setSliderValue] = useState([0, 10])

    const [exploded, setExploded] = useState(false)

    const [inPlayback, setInPlayback] = useState(false)

    const [currentGloc, setCurrentGloc] = useState(0)
    /*
    const [forceRender, setForceRender] = React.useState(0)    //include this as a depencency in every useEffect you want to be able to force re-render. this is not the "react" way but very hellpful in performance critical apps like this where you cannot state everything
    const forceReRender = () => {
        setForceRender(prev => prev + 1)
    }
    */

    //overwrite entity state on creation with props entity
    //this is because File is not a serializeable parameter
    useEffect(() => {

        console.log('my state:')
        console.log(entityState)
        setEntityState(_.clone(entity))

    }, [])

    useEffect(() => {
        drawMyUi(createRecoilDebugUI())
    }, [entityState])

    /*
    useEffect(() => {
        if (!_.isNil(entityState.url) && _.isString(entityState.url)) {
            parseGCodeFromURL(entityState.url)
        }
    }, [entityState.url])

    
    useEffect(() => {
        if (_.isNil(entityState.file)) {
            return
        }

        //self called anonymous function to prohibit main thread locking from main thread
        (async () => {
            //@ts-ignore    ts ignore cause typescript doesnt catch the return abvoe
            let fileContent = await readFileAsync(entityState.file)
            if (_.isString(fileContent)) parseGCodeFromString(fileContent)
        })()
    }, [entityState.file])
    */


    useEffect(() => {
        if (!_.isNil(entity.url)) {
            parseGCodeFromURL(entity.url)
        }
    }, [])

    const parseGCodeFromString = (gcode: string) => {
        //@todo: progress indicator split up the parsing in chunks of gcode to make this possible
        console.log('start parsing')
        new GCodeParser().parseGCode(gcode).then(parsedGCode => {
            setParsedGCode(parsedGCode)

            if (parsedGCode.typeNames.length > 0) {
                setCurrentDisplayModeId(DisplayModesEnum.TYPES)
            }
            console.log('parsed')
        })
    }

    const parseGCodeFromURL = async (url: string) => {
        let response: AxiosResponse | null = null

        try {
            response = await axios.get(url)

        } catch (e) {
            console.error('could not fetch debug gcode')
            console.error(e)

            console.log(lineSegments)
            return null
        }

        console.log('did not return')
        console.log(response)
        if (_.isNil(response)) return
        parseGCodeFromString(response.data)
    }


    const draw3D = async () => {
        console.log('called draw3d')
        if (_.isNil(parsedGCode)) return

        setLineSegments(await Promise.all([
            filterPointsToLineSegment(
                parsedGCode,
                p => { return !_.isNil(p.pointType) && p.pointType === PointType.extrude },
                'Extrude',
                new THREE.Color(colorNamesLookup[0])
            ),
            filterPointsToLineSegment(
                parsedGCode,
                p => { return !_.isNil(p.pointType) && p.pointType === PointType.translate },
                'Translate',
                new THREE.Color(colorNamesLookup[4])
            )
        ]))
    }

    //only shows the drawings done between given line of gcodes (glocs)
    const filterGloc = (glocStart: number, glocEnd: number) => {

        _.each(lineSegments, lineSegment => {

            //material id 1 is the visible material
            //set everything to visible because we will use the draw range to achieve results. this is easier in the loc case as the loc range can only occur continuous
            //and the points appear in the same order as the loc commands
            lineSegment.bufferGeometry.clearGroups()
            //@ts-ignore
            let usage = lineSegment.bufferGeometry.attributes.position.usage * 3 * 2 | 0
            lineSegment.bufferGeometry.addGroup(0, usage, 1)

            let glocIndexStart = -1
            let glocIndexEnd = -1
            for (let i = 0; i < lineSegment.glocIndices.length; i += 1) {
                if (glocIndexStart < 0 && lineSegment.glocIndices[i] >= glocStart) glocIndexStart = i * 2  //needs to be multiplied by 2 as in the index array every line is represented by 2 dots
                if (glocIndexEnd < 0 && lineSegment.glocIndices[i] >= glocEnd) glocIndexEnd = i * 2  //needs to be multiplied by 2 as in the index array every line is represented by 2 dots
            }
            //@ts-ignore
            lineSegment.bufferGeometry.drawRange = { start: glocIndexStart, count: Math.abs(glocIndexEnd - glocIndexStart) }
        })
    }

    const removeAllFilters = () => {
        _.each(lineSegments, lineSegment => {
            if (_.isNil(lineSegment)) return

            lineSegment.bufferGeometry.drawRange = { start: 0, count: Infinity }

            lineSegment.bufferGeometry.clearGroups()
        })
    }
    /* filter layer by height, from z to z */
    const filterLayer = (heightStart: number, heightEnd: number) => {


        _.each(lineSegments, lineSegment => {
            if (_.isNil(lineSegment)) return

            let keysString = _.keys(lineSegment.heightIndices)

            let keys = _.map(keysString, key => parseFloat(key))
            keys = _.filter(keys, key => key >= heightStart && key <= heightEnd)

            if (keys.length === 0) {
                //if no height information for the selected range, return and set to invisible
                //@todo set better invisibility
                lineSegment.bufferGeometry.drawRange = { start: 0, count: 0 }
                return
            } else {
                // if keys found set draw range to max. this bug permanently hid segments if they were completely out of draw range once
                lineSegment.bufferGeometry.drawRange = { start: 0, count: Infinity }
            }

            let ranges = _.flatMap(keys, key => lineSegment.heightIndices[key])

            lineSegment.bufferGeometry.clearGroups()

            //@ts-ignore
            lineSegment.bufferGeometry.addGroup(0, lineSegment.bufferGeometry.attributes.position.usage * 3 * 2, 0)

            //set the 9191
            for (let i = 0; i < ranges.length; i += 2) {
                let count = ranges[i + 1] - ranges[i]
                let start = ranges[i]

                lineSegment.bufferGeometry.addGroup(start, count, 1)
            }
        })
    }


    const drawFlow = async () => {
        if (_.isNil(parsedGCode)) return

        const flowBuckets: Array<{
            minFlow: number,
            maxFlow: number,
            color: THREE.Color
        }> = [{
            minFlow: -2,
            maxFlow: 0,
            color: new THREE.Color('red')
        }]

        const noBrackets = 10

        const minFlow = parsedGCode.stats.minFlow
        const maxFlow = parsedGCode.stats.maxFlow
        const rangeFlow = maxFlow - minFlow
        const stepFlow = rangeFlow / noBrackets
        const lowestFlowColor = new THREE.Color('coral')
        const highestFlowColor = new THREE.Color('pink')

        for (let i = 0; i < noBrackets; i += 1) {
            flowBuckets.push({
                minFlow: i * stepFlow,
                maxFlow: (i + 1) * stepFlow,
                color: new THREE.Color(lowestFlowColor).lerp(new THREE.Color(highestFlowColor), (i * stepFlow) / rangeFlow)
            })
        }

        setLineSegments(
            await Promise.all(_.map(flowBuckets, (flowBucket, i) => {
                const lineSegmentText = _.round(flowBucket.minFlow, 6) + ' to ' + _.round(flowBucket.maxFlow, 6)
                return filterPointsToLineSegment(
                    parsedGCode,
                    p => { return !_.isNil(p.pointType) && p.pointType === PointType.extrude && !_.isNil(p.flow) && p.flow >= flowBucket.minFlow && p.flow < flowBucket.maxFlow },
                    lineSegmentText,
                    flowBucket.color
                )
            }))
        )
    }


    const drawHeat = async () => {
        if (_.isNil(parsedGCode)) return

        if (parsedGCode.uniqueTemperatures.length === 0) return //no heat values cannot process, @todo: hide heat if no values

        const coldColor = new THREE.Color('blue')
        const hotColor = new THREE.Color('red')

        const uniqueTemps = _.filter(_.clone(parsedGCode.uniqueTemperatures), temp => temp > 0)
        console.log(uniqueTemps)
        //@ts-ignore
        const tempRange = parsedGCode.stats.maxTemp - parsedGCode.stats.minTemp
        const colors = _.map(uniqueTemps, uniqTemp => new THREE.Color(coldColor).lerp(new THREE.Color(hotColor), ((uniqTemp - parsedGCode.stats.minTemp) / tempRange) || .5))

        const extrudePoints = _.filter(parsedGCode.points, p => !_.isNil(p.pointType) && p.pointType === PointType.extrude)     //only extrude points can have temperatures


        setLineSegments(
            await Promise.all(
                _.map(uniqueTemps, async (uniqueTemperature, i) => {
                    const points = _.filter(extrudePoints, p => p.temp === uniqueTemperature)
                    const geometry = new THREE.BufferGeometry()

                    const [typedPoints, heightIndices, glocIndices] = await writePointsToArray(points)
                    geometry.setAttribute('position', new THREE.BufferAttribute(typedPoints, 3))
                    geometry.addGroup(0, Infinity, 1)         //everything
                    console.log(geometry)

                    return {
                        bufferGeometry: geometry,
                        heightIndices: heightIndices,
                        glocIndices: glocIndices,
                        materialAttributes: {
                            color: colors[i]
                        },
                        visible: true,
                        logc: points.length,
                        key: uniqueTemperature.toString()
                    }
                })
            )
        )
    }

    // takes the parsed gcode object, filters points according to a given filter function and returns
    // an array with all the data necessary to build a <lineSegment> for three.js
    const filterPointsToLineSegment = async (parsedGCode: ParsedGCode, filterFunction: (p: DataPoint) => boolean, key: string, color: THREE.Color): Promise<LineSegment> => {

        const filteredPoints = _.filter(parsedGCode.points, filterFunction)

        //@ts-ignore
        if (filteredPoints.length < 2) return

        //@ts-ignore
        const [typedPoints, heightIndices, glocIndices] = await writePointsToArray(filteredPoints)

        const geometry = new THREE.BufferGeometry()
        geometry.addGroup(0, filteredPoints.length * 2, 1)
        geometry.setAttribute('position', new THREE.BufferAttribute(typedPoints, 3))

        return {
            bufferGeometry: geometry,
            heightIndices: heightIndices,
            glocIndices: glocIndices,
            materialAttributes: {
                color: color
            },
            visible: true,
            logc: filteredPoints.length,
            key: key
        }
    }

    //separate moves by point subtypes (from superslic3r for example) only appear in extrude-points, not in other ones
    const drawTypes = () => {
        if (_.isNil(parsedGCode)) return

        Promise.all(_.map(parsedGCode.typeNames, (typeName, i) => {
            return filterPointsToLineSegment(parsedGCode, p => { return !_.isNil(p.pointType) && p.pointType === PointType.extrude && p.type === i }, typeName, new Color(colorNamesLookup[i]))
        })).then(segments => {
            setLineSegments(_.orderBy(_.filter(segments, segment => { return segment.logc > 2 }), ['logc'], ['desc']))
        })
    }

    useEffect(() => {
        if (_.isNil(currentDisplayModeId)) return
        switch (currentDisplayModeId) {
            case DisplayModesEnum['3D']:
                draw3D()
                break
            case DisplayModesEnum.FLOW:
                drawFlow()
                break
            case DisplayModesEnum.HEAT:
                drawHeat()
                break
            case DisplayModesEnum.TYPES:
                drawTypes()
                break
            default:
            //do nothing/set to 3d
        }

        //drawMyUi(constructMyUi())

    }, [currentDisplayModeId, parsedGCode])

    /*
    useEffect(() => {
        drawMyUi(constructMyUi())
    }, [lineSegments])
    */

    const createRecoilDebugUI = (): JSX.Element => {
        return <div>
            <Button id="findme" onClick={() => {
                setEntityState(prev => {
                    let ret = _.clone(prev)
                    ret.url = 'test'
                    return ret
                })
            }}>
                set state of entity
            </Button>
            {JSON.stringify(entityState)}
        </div>
    }

    const constructMyUi = (): JSX.Element => {
        {/* visibility buttons to turn on and off calculated line segments */ }

        return <>
            <div style={{ position: 'absolute', top: '0', left: '0' }}>
                <Stack direction="column" spacing={2}>
                    {
                        _.map(lineSegments, (lineSegment, i) => {
                            if (_.isNil(lineSegment)) return
                            return <PlasColoredButton
                                text={lineSegment.key + ' (' + lineSegment.logc + ')'}
                                active={lineSegment.visible}
                                size="small"
                                color={lineSegment.materialAttributes.color}
                                key={lineSegment.key}
                                onClick={() => {
                                    console.log('set segment ' + i + ' with key ' + lineSegment.key + ' to ' + !lineSegments[i].visible)
                                    setLineSegments(lineSegments => {
                                        lineSegments = _.clone(lineSegments)
                                        lineSegments[i].visible = !lineSegments[i].visible
                                        return lineSegments
                                    })
                                }}
                            />
                        })
                    }
                </Stack>
            </div>

            {/* top button row to select different display modes */}
            <div style={{ position: 'absolute', top: '10px', right: '50px' }}>
                <Stack direction="row" spacing={2}>


                    {/*debug button */}
                    <Button
                        key="test"
                        onClick={() => {
                            startPlayback()
                        }}
                        size="small"
                    >
                        playback gcode
                    </Button>



                    {
                        _.map(displayParams.displayModes, displayMode => {
                            if (_.isNil(parsedGCode)) return
                            if (!displayMode.availableFunction(parsedGCode)) return

                            return <Button
                                key={displayMode.buttonText}
                                onClick={() => {
                                    setCurrentDisplayModeId(displayMode.id)
                                }}
                                size="small"
                                variant={displayMode.id === currentDisplayModeId ? 'contained' : 'outlined'}>
                                {displayMode.buttonText}
                            </Button>
                        })
                    }
                </Stack>
            </div>



            <div style={{ height: '94vh', padding: '3vh 1vh 3vh 1vh', position: 'absolute', top: 0, right: 0 }}>
                {!_.isNil(parsedGCode) &&
                    <Slider
                        orientation="vertical"
                        value={sliderValue}
                        //@ts-ignore
                        onChange={(e, v: number[]) => {
                            filterLayer(v[0], v[1])
                            setSliderValue(v)
                        }}
                        track="normal"
                        step={0.2}
                        min={_.min(parsedGCode.zIndices)}
                        max={_.max(parsedGCode.zIndices)}
                    />
                }
            </div>

            {/* @ts-ignore */}
            {!_.isNil(parsedGCode) && true === false && parsedGCode.points.length > 0 &&
                <div style={{ height: '94vh', overflow: 'scroll', width: '25vw', padding: '3vh 1vh 3vh 1vh', position: 'absolute', top: 0, left: 0 }}
                    dangerouslySetInnerHTML={{ __html: _.join(_.map(parsedGCode.points, p => p.text), '<br />') }}
                >
                </div>
            }
        </>
    }


    const startPlayback = () => {
        if (inPlayback) return
        setInPlayback(true)
        //@ts-ignore
        for (let i = 0; i < parsedGCode?.points.length; i += 20) {
            setTimeout(() => {
                filterGloc(0, i)
                setCurrentGloc(i)
            }, 10)
        }

        setTimeout(() => {
            filterLayer(0, 100)
            setInPlayback(false)
        }, 20)
    }

    return <group onPointerOver={e => { }}>
        {/* output all line segments */}


        {/* if no line segments, display plasmics loading icon */}
        {lineSegments.length === 0 &&
            <Suspense fallback={null}>
                <Plasmicslogo scale={.1} position={[0, 1.2, 0]} />
            </Suspense>
        }


        <Flex justifyContent="center" alignItems="center" position={[100, 10, 0]} rotation={[0, -Math.PI / 2, 0]}>
            {_.map(lineSegments, (lineSegment, i) => {

                if (_.isNil(lineSegment)) return <></>
                return <ThreeBox centerAnchor key={lineSegment.key}>
                    <Interactive onSelect={() => {
                        console.log('vr selected')
                        //console.log('set segment ' + i + ' with key ' + lineSegment.key + ' to ' + !lineSegments[i].visible)

                        setLineSegments(lineSegments => {
                            lineSegments = _.clone(lineSegments)
                            lineSegments[i].visible = !lineSegments[i].visible
                            return lineSegments
                        })

                    }} onHover={() => { }} onBlur={() => { }}>
                        <Text
                            scale={[100, 100, 100]}
                            color={lineSegment.visible ? _.clone(lineSegment.materialAttributes.color) : 'grey'}
                        >
                            {lineSegment.key + ' (' + lineSegment.logc + ')'}
                        </Text>
                    </Interactive>
                </ThreeBox>
            })
            }
        </Flex>

        {lineSegments.length > 0 &&
            <SliceTable filterLayer={filterLayer} removeAllFilters={removeAllFilters} />
        }


        <Interactive onSelect={() => { setExploded(prev => !prev) }}>
            <Text
                scale={[100, 100, 100]}
                position={[0, 10, -100]} rotation={[0, 0 * Math.PI / 2, 0]}
                color={exploded ? 'yellow' : 'grey'}

            >
                Explode Layers
            </Text>
        </Interactive>


        <Interactive onSelect={() => {
            startPlayback()
        }}>
            <Text
                scale={[100, 100, 100]}
                position={[0, 30, -100]} rotation={[0, 0 * Math.PI / 2, 0]}
                color="magenta"
            >
                {!inPlayback &&
                    "Start Playback"
                }
            </Text>
        </Interactive>

        <group scale={.01} position={[0, 1, 0]}>

            {inPlayback &&
                <Text rotation={[0, 0, 0]} position={[40, 10, -50]} color={"magenta"} fontSize={5} anchorX="center" anchorY="bottom">
                    line of gcode: {currentGloc}
                </Text>
            }
            {exploded &&
                <Flex flexDir={'row'} position={[0, 10, 0]} justifyContent={'center'} alignContent={'baseline'}>
                    {
                        _.map(lineSegments, lineSegment => {
                            if (_.isNil(lineSegment)) return

                            return <ThreeBox margin={10} key={lineSegment.key}>
                                <lineSegments
                                    key={lineSegment.key}
                                    visible={lineSegment.visible}
                                    geometry={lineSegment.bufferGeometry}
                                >
                                    <lineBasicMaterial
                                        transparent={true}
                                        attachArray={"material"}
                                        opacity={0}
                                        {...lineSegment.materialAttributes}
                                    />

                                    <lineBasicMaterial
                                        transparent={false}
                                        attachArray={"material"}
                                        opacity={0.7}
                                        {...lineSegment.materialAttributes}
                                    />
                                </lineSegments>

                                <Text rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 50]} color={lineSegment.materialAttributes.color} fontSize={5} anchorX="center" anchorY="bottom">
                                    {lineSegment.key}
                                </Text>
                            </ThreeBox>
                        })
                    }
                </Flex>
            }


            {!exploded &&
                _.map(lineSegments, lineSegment => {
                    if (_.isNil(lineSegment)) return

                    return <lineSegments
                        key={lineSegment.key}
                        visible={lineSegment.visible}
                        geometry={lineSegment.bufferGeometry}
                    >
                        <lineBasicMaterial
                            transparent={true}
                            attachArray={"material"}
                            opacity={0}
                            {...lineSegment.materialAttributes}
                        />

                        <lineBasicMaterial
                            transparent={false}
                            attachArray={"material"}
                            opacity={0.7}
                            {...lineSegment.materialAttributes}
                        />
                    </lineSegments>
                })
            }
        </group>


        <VRConsole />
    </group >
}