import { getRecoil } from "../Recoil/RecoilGlobalManager"
import { getRoomStateAtom, RoomUser } from "../Recoil/RoomStateAtom"
import { getConnectionStateAtom } from "../Recoil/ConnectionState"
import _, { uniqueId } from 'lodash'
import { WebSocketSingleton } from "./WebSocketSingleton"
import { WebSocket } from "isomorphic-ws"
import { SyncedEntity } from '../Entity/SyncedEntity'

export interface RequestRoomUsersListCall {
    action: 'invoke',
    invoke: 'requestRoomUsersList',
    data: {
        roomId: string
    }
}

export interface RequestJoinRoomCall {
    action: 'invoke',
    invoke: 'requestJoinRoom',
    data: {
        username: string
        roomId: string
    }
}

export interface RequestSendToRoomCall {
    action: 'invoke',
    invoke: 'sendToRoom',
    data: {
        roomId: string,
        payload: {
            message: string
        }
    }
}

export interface NewSyncedEntityCall {
    action: 'invoke',
    invoke: 'requestNewEntity',
    data: {
        roomId: string,
        entity: SyncedEntity
    }
}
export interface RequestNewRoom {
    action: 'invoke',
    invoke: 'requestNewRoom'
}

export interface addEntityCall {
    action: 'invoke',
    invoke: 'addEntity',
    data: any
}

export type WebsocketCall = (WebsocketRequestRoomEntityStateCall | RequestNewEntityCall | RequestRoomUsersListCall | RequestJoinRoomCall | RequestNewRoom | RequestSendToRoomCall)

export type WebsocketBroadcast = {
    action: 'invoke',
    invoke: 'broadcast',
    data: DataEntityNewProps | DataRequestEntityResync
}

export type DataEntityNewProps = {
    roomId: string,
    entityId: string,
    action: 'newPropsSyncedEntity',
    newProps: any
}


export type DataRequestEntityResync = {
    roomId: string,
    action: 'requestEntityResync'
}


export type RequestNewEntityCall = {
    action: 'invoke',
    invoke: 'requestNewEntity',
    data: SyncedEntity
}

export type BroadcastIncomingResync = {
    roomId: string,
    entityId: string,
    action: 'resyncEntities'
}


export type WebsocketRequestRoomEntityStateCall = {
    action: 'invoke',
    invoke: 'requestRoomEntityState',
    data: {
        roomId: string
    }
}

export type WebsocketResponse = (RequestRoomUsersListResponse | RequestNewRoomResponse | RequestJoinRoomResponse)

type RequestResponseGeneral = {
    id: string,
    result: 'success' | 'error'
}
export type RequestNewRoomResponse = RequestResponseGeneral & {
    roomId: string,
    text: string
}


export type RequestJoinRoomResponse = RequestResponseGeneral & {
    roomId: string,
    text: string
}


export type RequestRoomUsersListResponse = RequestResponseGeneral & {
    invoke: 'requestRoomUsersList',
    data: {
        roomId: string,
        listOfUsersPresent: Array<RoomUser>
    }
}




/*
public getRoomUsers = async (roomId: string): Promise<Array<RoomUser> | void> => {

    return new Promise((resolve, reject) => {
        const msgId = Math.random().toString(36)

        const msg = `{"action":"invoke","invoke":"requestRoomUsersList","data":{"roomId":"${roomId}"}}`

        this.socket.send(msg)


        const parseRoomUsers = (dataStrng: string) => {
            const data = JSON.parse(dataStrng) as any

            if (data.invoke !== 'requestRoomUsersList') return

            this.socket.off('message', parseRoomUsers)
        }
        this.socket.on('message', parseRoomUsers)
    })
}
*/

export const socketBroadcast = async (call: WebsocketBroadcast) => {
    let ws: WebSocketSingleton

    try {
        ws = await WebSocketSingleton.getInstance()

        ws.socket.send(JSON.stringify(call))
    } catch (e) {
        console.error(e)
        console.log('error getting socket')
        return
    }
}


export const subscribeToIncoming = async (f: (response: WebsocketResponse) => void) => {
    try {
        let ws = await WebSocketSingleton.getInstance()
        ws.addCallbackBroadcast(f)
    } catch (e) {
        console.error(e)
        console.log('could not subscribe')
    }
}

export const unsubscribeToIncoming = async (f: (response: WebsocketResponse) => void) => {
    try {
        let ws = await WebSocketSingleton.getInstance()
        ws.removeCallback(f)

    } catch (e) {
        console.error(e)
        console.log('could not unsubscribe')
    }
}


export const socketCallAndResponse = (call: WebsocketCall): Promise<WebsocketResponse> => {
    return new Promise(async (resolve, reject) => {

        let ws: WebSocketSingleton

        try {
            ws = await WebSocketSingleton.getInstance()
        } catch (e) {
            console.error(e)
            console.log('error getting socket')
            reject()
            return
        }

        console.log('waited for connect')
        console.log(ws!)

        // generate unique id
        const id = Math.random().toString(36)

        // extend the call 
        const callUniqe: WebsocketCall & { id: string } = {
            ...call,
            id: id
        }

        // create eventhandler function to listen for this specific id
        const response = (response: any) => {
            resolve(response)
        }

        // register eventhandler to listen for this specific id
        ws!.addCallback({ f: response, id: id })

        //send call
        ws!.socket.send(JSON.stringify(callUniqe))
    })
}

export class WebSocketManager {

    constructor() {

    }


}