import { RecoilValue, RecoilState, useRecoilCallback } from 'recoil'

interface RecoilGlobalmanager {
    get?: <T>(atom: RecoilValue<T>) => T
    getPromise?: <T>(atom: RecoilValue<T>) => Promise<T>
    set?: <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void
    reset?: (atom: RecoilState<any>) => void
}

const recoilGlobalManager: RecoilGlobalmanager = {}

export default function RecoilGlobalmanager() {

    recoilGlobalManager.get = useRecoilCallback<[atom: RecoilValue<any>], any>(({ snapshot }) =>
        function <T>(atom: RecoilValue<T>) {
            return snapshot.getLoadable(atom).contents
        },
        [])

    recoilGlobalManager.getPromise = useRecoilCallback<[atom: RecoilValue<any>], Promise<any>>(({ snapshot }) =>
        function <T>(atom: RecoilValue<T>) {
            return snapshot.getPromise(atom)
        },
        [])

    //@ts-ignore
    recoilGlobalManager.set = useRecoilCallback(({ set }) => set, [])

    recoilGlobalManager.reset = useRecoilCallback(({ reset }) => reset, [])

    return <></>
}

export function getRecoil<T>(atom: RecoilValue<T>): T {
    return recoilGlobalManager.get!(atom)
}

export function getRecoilPromise<T>(atom: RecoilValue<T>): Promise<T> {
    return recoilGlobalManager.getPromise!(atom)
}

export function setRecoil<T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
    recoilGlobalManager.set!(atom, valOrUpdater)
}

export function resetRecoil(atom: RecoilState<any>) {
    recoilGlobalManager.reset!(atom)
}