import { atomFamily, atom, useRecoilValue, useRecoilState } from "recoil"
import _ from 'lodash'
import { websocketUrl } from "../constants"
import { socketBroadcast, subscribeToIncoming, unsubscribeToIncoming, DataEntityNewProps, WebsocketResponse, WebsocketBroadcast } from "../Socket/WebSocketManager"
import { getRecoil } from "../Recoil/RecoilGlobalManager"

export type SyncedEntity = {
    id: string
    type: 'mesh' | 'gcode' | 'testCube'
    url: string | null,
    props: {
        position: [number, number, number]
        scale: [number, number, number]
        color: string
    }
    createdBy: string
    roomId: string
}


//get new entity atom with given id as default id
export const getSyncedEntityAtom = atomFamily<SyncedEntity, { id: string }>({
    key: 'syncedEntityFamily',
    default: ({ id }) => {
        return {
            id: id,
            roomId: '',
            type: 'testCube',
            url: '',
            createdBy: '',
            props: {
                color: _.sample(['red', 'white', 'blue', 'green']) as string,
                position: [_.random(-2.0, 2.1), _.random(-2.0, 2.1), _.random(-2.0, 2.1)],
                scale: [1, 1, 1]
            }
        }
    },
    effects: [
        //effect 1, sync to cloud
        ({ node, onSet, setSelf }) => {

            onSet(async (syncedEntityValues) => {
                console.log('broadcast new props ' + syncedEntityValues.props.position)

                const call = {
                    action: 'invoke',
                    invoke: 'broadcast',
                    data: {
                        action: 'newPropsSyncedEntity',
                        roomId: syncedEntityValues.roomId,
                        entityId: syncedEntityValues.id,
                        newProps: syncedEntityValues.props
                    }
                } as WebsocketBroadcast

                console.log(JSON.stringify(call))
                await socketBroadcast(call)
            })



            const callbackRemoteChange = (incoming: DataEntityNewProps) => {
                let me = getRecoil(node)

                if (_.isNil(incoming.action)) return

                if (incoming.action === 'newPropsSyncedEntity' && incoming.entityId === me.id) {
                    console.log('update for me! ' + me.id)

                    setSelf({
                        ...me,
                        props: {
                            ...incoming.newProps
                        }
                    })
                }
                //if(response.result)
                /*
                console.log('received response in syced entity')
                console.log()
                //console.log(response)
                return 'null'
                */
            }

            // subscribe to incoming change-messages, filter for state id internally
            // @todo: refactor this into websocket manager
            //@ts-ignore
            subscribeToIncoming(callbackRemoteChange)

            //@ts-ignore
            return () => { unsubscribeToIncoming(callbackRemoteChange) }
        }
    ]
})

// singleton to always get the same synced entity list from everywhere
export const getSyncedEntityListAtom = atomFamily<Array<SyncedEntityShell>, {}>({
    key: 'entityList',
    default: []
})



export type SyncedEntityShell = Pick<SyncedEntity, "id">

export const SyncedEntityShell: React.FC<SyncedEntityShell> = ({ id }) => {

    const [myselfAtom, setMyselfAtom] = useRecoilState(getSyncedEntityAtom({ id: id }))

    return <mesh key={myselfAtom.id} onClick={() => {
        console.log('clicked ' + myselfAtom.id)
        setMyselfAtom(prev => {
            return {
                ...prev,
                props: {
                    ...prev.props,
                    position: [_.random(-1.1, 1.1), _.random(-1.1, 1.1), _.random(-1.1, 1.1)]
                }
            }
        })


    }} position={myselfAtom.props.position} scale={myselfAtom.props.scale}>
        <boxBufferGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color={myselfAtom.props.color} />
    </mesh>
}