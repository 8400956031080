import { useXR } from "@react-three/xr"
import React, { useEffect, useState } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, Text } from '@react-three/drei'

interface VRConsole {
}

/**
 * Prints the last line of console output to VR readable text
 * @param param0 V
 * @returns 
 */
export const VRConsole: React.FC<VRConsole> = () => {

    const [lastLine, setLastLine] = React.useState("")
    const { controllers, player, isPresenting } = useXR()



    useEffect(() => {
        /*
        if (isPresenting) {
            const log = console.log.bind(console)
            console.log = (...args) => {
                setLastLine(args[0])
                log(...args)
            }
        }
        */
    }, [isPresenting])




    return <Text
        scale={[100, 100, 100]}
        position={[100, 10, -300]}
        rotation={[0, -Math.PI / 2, 0]}
        color={'grey'}
        anchorX="center" // default
        anchorY="top" // default
    >
        {lastLine}
    </Text>

}