import _ from 'lodash'
const numberAccuracy = 5

export enum PointType {
    extrude,
    translate,
    control,
    comment
}

enum AddMode {
    absolute,
    incremental
}

export type DataPoint = {
    command: string
    x: number                       //absolute X coord
    y: number                       //absolute Y coord
    z: number                       //absolute Z coord
    pointType?: PointType              //see enum
    length?: number                  //length of movement. is zero if controlcommand or other
    temp?: number                    //arbitrary number, will be scaled
    tool?: string                    //identifier for the used tool. for future use
    e?: number                   //absolute E value
    eRelative?: number
    flow?: number               //arbitrary number, will be scaled 
    text?: string               //comment or other texts associated with the point
    prev?: DataPoint            //the previous point, used to draw the proper lines
    type?: number               //reference number to the type of fill, primarily used by superslic3r
    gloc?: number               //the line of gcode the datapoint is correspoinding to
}


export interface ParsedGCode {
    points: Array<DataPoint>
    typeNames: Array<string>
    zIndices: number[],
    uniqueTemperatures: number[],
    stats: {
        noGlocs: number
        minFlow: number
        maxFlow: number
        meanFlow: number
        minTemp: number
        maxTemp: number
        meanTemp: number
    }
}



const getNumber = (coord: 'X' | 'Y' | 'Z' | 'E' | 'S' | 'P' | 'S', lineFragments: string[]): number | null => {
    for (let lineFragment of lineFragments) {
        if (lineFragment.startsWith(coord)) return _.round(parseFloat(lineFragment.slice(1)), numberAccuracy)
    }
    return null
}


export class GCodeParser {
    reset = () => {
        //not really needed as one gcode parser is instanced per gcode component
    }

    parseGCode = async (gcode: string): Promise<ParsedGCode> => {
        gcode = _.replace(gcode, /\r/g, '')     //replace all possible \n\r with \n

        //console.log(gcode)

        //mass declare all vars, some are unused intentionally for future implementation
        let offX = 0,
            offY = 0,
            offZ = 0,
            offE = 0,
            temp = 0,
            minFlow = 999999,
            maxFlow = 0,
            minTemp = 999999,
            maxTemp = 0,
            tool = 'tool0',
            type = -1,
            addMode: AddMode = AddMode.absolute

        //let lineFragments = new Array<string>()
        let zeroPoint: DataPoint = {
            command: 'G999',
            x: 0,
            y: 0,
            z: 0,
            e: 0,
        }


        const lines = _.split(gcode, '\n')      //check if this is viable for the future or if stream-processing is smarter
        let points = new Array<DataPoint>()
        const typeNames: Array<string> = []     //array to store all type names (for superslic3r)
        let zIndices: number[] = []             //array to save all unique z values
        let uniqueTemperatures: number[] = []       //array to save all unique temperature values

        points.push(zeroPoint)                  //push initial point

        let currentLine = 0
        let batchSize = 1000
        let noLines = lines.length


        const parseLines = async (fromLine: number, toLine: number) => {

            toLine = _.min([toLine, lines.length]) || toLine

            for (let i = fromLine; i < toLine; i++) {
                //split up command and convert everything to uppercase
                const lineText = lines[i]

                let lineFragments = _.map(lineText.split(' '), fragment => _.toUpper(fragment))

                //if just an empty line or just a comment start like ';' step over
                //if (lineFragments.length === 1 && lineFragments[0] === '') continue

                let prevPoint = points[points.length - 1]


                //assign every point with an XYZ coord and the command fragment. in GCode control fragments do not have coordinates but by copying the previous points coords 
                //its easier to visualize the point in the print at which the control command occurs
                let point: DataPoint = {
                    command: lineFragments[0],
                    x: offX + (addMode === AddMode.incremental ? prevPoint.x : 0) + (getNumber('X', lineFragments) || prevPoint.x),
                    y: offY + (addMode === AddMode.incremental ? prevPoint.y : 0) + (getNumber('Y', lineFragments) || prevPoint.y),
                    z: offZ + (addMode === AddMode.incremental ? prevPoint.z : 0) + (getNumber('Z', lineFragments) || prevPoint.z),
                    e: prevPoint.e
                }

                point.text = lineText       //write complete line into text property


                // if line is a comment deal with it and step over without switch
                if (point.command.startsWith(';')) {
                    //if (point.command === ';') continue

                    const text = point.command.slice(1)

                    if (text.startsWith('TYPE:')) {
                        //in superslic3r specific type debugs, this is used to segregate the extrusion moves

                        let [command, typeName] = lineText.split(':', 2)

                        point.command = ';' + command;

                        const typeIndex = _.indexOf(typeNames, typeName)
                        if (typeIndex < 0) {
                            typeNames.push(typeName)

                            console.log(typeName)
                            type = typeNames.length - 1
                        } else {
                            type = typeIndex
                        }
                    } else {
                        point.command = ';'
                        point.pointType = PointType.comment
                    }

                    points.push(point)
                    continue
                }

                switch (lineFragments[0]) {
                    case 'G0':              //move linear
                    case 'G00':             //move linear
                    case 'G1':              //move linear
                    case 'G01':             //move linear
                        point.pointType = _.indexOf(lineText, 'E') > -1 ? PointType.extrude : PointType.translate

                        //if printer performs an extrude move and the previous point is not an extrude type, we need to specifically save the previous point
                        //so if we filter for extrusion moves we do not jumble the order of points
                        //if you filter for extrude moves only and delte all the translate moves
                        //an extrude move with a preceeding translate move gets a different ancestor
                        //this leads to extrude moves being displayed which are no extrude moves
                        /*
                        if (point.pointType === PointType.extrude && !_.isNil(prevPoint.pointType) && prevPoint.pointType !== PointType.extrude) {
                            point.prev = prevPoint
                        }
                        */
                        point.prev = prevPoint
                        point.temp = temp
                        point.tool = tool
                        point.length = _.round(((point.x - prevPoint.x) ** 2 + (point.y - prevPoint.y) ** 2 + (point.z - prevPoint.z) ** 2) ** 0.5, numberAccuracy)

                        if (point.pointType === PointType.extrude) {
                            //set E value and calculate flow

                            point.e = offE + (getNumber('E', lineFragments) || 0)
                            point.eRelative = point.e - (point.prev.e || 0)
                            let flow = _.round(point.eRelative / point.length, numberAccuracy)

                            if (flow < 0) {
                                point.flow = -1
                            }
                            // sane value check
                            if (flow > 0 && flow < 1) {
                                point.flow = flow
                                if (flow < minFlow) minFlow = flow
                                if (flow > maxFlow) maxFlow = flow
                            } else {

                            }

                        }

                        zIndices.push(point.z)
                        zIndices = _.uniq(zIndices)
                        break
                    case 'G2':              //move arc
                    case 'G02':             //move arc
                    case 'G3':              //move arc
                    case 'G03':             //move arc
                        //@todo: implement this
                        break
                    case 'G28':             //reset all axis to zero
                        point = _.clone(zeroPoint)
                        break
                    case 'G90':             //set to absolute coords
                        addMode = AddMode.absolute
                        point.pointType = PointType.control
                        point.text = 'Set To Absolute Coords'
                        break
                    case 'G91':             //set to incremental
                        addMode = AddMode.incremental
                        point.pointType = PointType.control
                        point.text = 'Set To Relative Coords'
                        break
                    case 'G92':             //set new offset for future coords
                        offX = getNumber('X', lineFragments) || offX
                        offY = getNumber('Y', lineFragments) || offY
                        offZ = getNumber('Z', lineFragments) || offZ
                        offE = getNumber('E', lineFragments) || offE

                        // if there is an E value in the reset command write it as new point e value
                        if (_.indexOf(lineText, 'E') > -1) {
                            point.e = getNumber('E', lineFragments) || 0
                        }

                        break
                    case 'M106':
                        point.pointType = PointType.control
                        let fanIndex = getNumber('P', lineFragments) || 0
                        let fanSpeed = getNumber('S', lineFragments) || -1
                        point.text = 'Set Fan No ' + fanIndex + ' to ' + fanSpeed
                        break;
                    case 'M107':
                        point.pointType = PointType.control
                        point.text = 'Fan Off'
                        break
                    case 'M104':            //set new temperature
                    case 'M109':
                        point.temp = getNumber('S', lineFragments) || 0
                        point.pointType = PointType.control
                        temp = point.temp
                        uniqueTemperatures.push(temp)
                        uniqueTemperatures = _.uniq(uniqueTemperatures)
                        if (temp > 0 && temp < minTemp) minTemp = temp
                        if (temp > maxTemp) maxTemp = temp
                        break
                    default:
                        break
                }

                if (lines[i].indexOf(';') > -1) {
                    // if line contains an inline comment write it to text
                    point.text = lines[i].slice(lines[i].indexOf(';'), lines[i].length)
                }

                point.type = type
                point.gloc = i + 1

                //if we have no e value carry over the e value from the previous point
                if (_.isNil(point.e)) point.e = prevPoint.e
                if (_.isNil(point.e)) point.e = 0

                points.push(point)
                // assemble final point
            }
        }



        while (currentLine < noLines) {
            await new Promise((resolve, reject) => {
                setTimeout(async () => {
                    await parseLines(currentLine, currentLine + batchSize)
                    resolve('ok')
                }, 0)
            })

            currentLine += batchSize
        }

        const meanFlow = _.mean(_.map(_.filter(points, point => !_.isNil(point.flow)), point => point.flow))
        console.log('meanFlow:' + meanFlow)
        console.log('minFlow: ' + minFlow)
        console.log('maxFlow: ' + maxFlow)
        console.log('minTemp: ' + minTemp)
        console.log('maxTemp: ' + maxTemp)

        return {
            points: points,
            typeNames: typeNames,
            zIndices: _.orderBy(zIndices),
            uniqueTemperatures: _.orderBy(uniqueTemperatures),
            stats: {
                noGlocs: lines.length,
                minFlow: minFlow,
                maxFlow: maxFlow,
                meanFlow: meanFlow,
                minTemp: minTemp,
                maxTemp: maxTemp,
                meanTemp: -1
            }
        }
    }
}

export type HeightIndices = {
    [index: number]: number[]
}

export type LocIndices = Uint32Array


// write a given array of datapoints to a typed float32 array with a coinciding height-array.
// the height array saves the index-ranges for a present height-value
// the loc array saves the line of code the corresponding array index i is happening
// the index i needs to be multipled by 2 outside to get the true draw to index
export type WritePointsToArray = (points: Array<DataPoint>) => Promise<[Float32Array, HeightIndices, LocIndices]>

/**
 * desynced wrapper for writePointsToArrayInternal
 * @param points Array<DataPoint> points to write to a typed float32 array 
 * @returns typed float32 array, array of height indices, array with gloc indices for quick filtering
 */
export const writePointsToArray: WritePointsToArray = async points => {
    return new Promise<[Float32Array, HeightIndices, LocIndices]>((resolve, reject) => {
        setTimeout(() => {
            resolve(writePointsToArrayInternal(points))
        }, 0)
    })
}


const writePointsToArrayInternal: WritePointsToArray = async points => {
    const arr = new Float32Array(points.length * 3 * 2)
    //@todo: make this more memory effective via indices without fucking up continuity
    let heightIndices: HeightIndices = {}
    let locIndices = new Uint32Array(points.length * 2)

    for (let i = 1; i < points.length; i += 1) {
        let p = points[i]

        if (!_.isNil(p.gloc)) {
            locIndices[i] = p.gloc
        }
        let prev = _.isNil(p.prev) ? points[i - 1] : p.prev
        let wi = i * 3 * 2      //write index

        arr[wi + 0] = prev.x
        arr[wi + 1] = prev.z
        arr[wi + 2] = prev.y
        arr[wi + 3] = p.x
        arr[wi + 4] = p.z
        arr[wi + 5] = p.y

        if (_.isNil(heightIndices[p.z])) {
            heightIndices[p.z] = [i * 2]
        } else {
            heightIndices[p.z].push(i * 2)
        }
    }

    //this sorts the height indices into brackets. first the indices of all points in a certain height 
    //get written to the array
    //afterwards every index that is 2 points away from another index (aka in a bracket) gets removed
    //usually this yields a start and end index for every height but would also support multiple height brackets
    //which could occur
    for (let key of _.keys(heightIndices)) {

        let nr = parseFloat(key)
        const indicesToKeep: number[] = []

        for (let i = 0; i < heightIndices[nr].length; i += 1) {
            let curr = heightIndices[nr][i]
            let prev = heightIndices[nr][i - 1]
            let next = heightIndices[nr][i + 1]

            if (curr !== (prev + 2) || curr !== (next - 2)) {
                indicesToKeep.push(i)
            }
        }

        let heightArr = _.map(indicesToKeep, indexToKeep => heightIndices[nr][indexToKeep])
        heightIndices[nr] = heightArr
    }

    //"massage" the locs array
    return [arr, heightIndices, locIndices]
}