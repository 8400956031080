import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import './index.css'
import { Slicer } from './Slicer'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import RecoilGlobalmanager from './Recoil/RecoilGlobalManager'
import { BrowserRouter } from 'react-router-dom'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    text: { primary: '#eee' },

    primary: {
      dark: '#F3933F',
      main: '#F3933F'
    },
    background: { paper: 'rgba(34,34,34,.9)' }
  }
})


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <RecoilGlobalmanager />
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Slicer />
        </ThemeProvider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

