import { Dropzone } from './Dropzone'
import logoGrey from './imgs/logobw.png'
import _, { startsWith, template, memoize } from 'lodash'
import { Box, Button, Slider, Stack } from '@mui/material'
import { EffectComposer, SSAO, SMAA } from "@react-three/postprocessing"
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { PerspectiveCamera, OrbitControls, TransformControls } from '@react-three/drei'
import * as THREE from 'three'
import { BufferGeometry, Color } from 'three'
import { useMainStore, DisplayModesEnum } from './Stores/MainStore'
import { desync, readFileAsync } from './utils'
import { colorNamesLookup } from './constants'
import { PlasColoredButton } from './PlasUIComponents/PlasColoredButton'
import React, { Suspense, useEffect, useState } from 'react'
import { DisplayGCodeRecoil } from './DisplayGCodeRecoil'
import { DefaultXRControllers, Hands, VRCanvas, XRController } from '@react-three/xr'
import { VRInjector } from './VRInjector'
import Plasmicslogo from './Models/Plasmicslogo'
import { v4 as uuidv4 } from 'uuid'
import { RecoilRoot, atom, useSetRecoilState, useRecoilState, useRecoilValue, useRecoilBridgeAcrossReactRoots_UNSTABLE } from 'recoil'
import { entityListAtom, getEntityAtom } from './Recoil/entity'
import { DebugObserver } from './Recoil/DebugObserver'
import { SnackbarProvider } from 'notistack'
import { getSyncedEntityAtom, getSyncedEntityListAtom, SyncedEntity, SyncedEntityShell } from './Entity/SyncedEntity'
import { DataRequestEntityResync, socketCallAndResponse, subscribeToIncoming, unsubscribeToIncoming } from './Socket/WebSocketManager'

import { getConnectionStateAtom } from "./Recoil/ConnectionState"
import { getRecoil, setRecoil } from './Recoil/RecoilGlobalManager'


const DebugEntityState: React.FC<{ id: string }> = ({ id }) => {
    const [state, setState] = useRecoilState(getEntityAtom({ id: id }))

    useEffect(() => {
        console.debug('debug entity state:', state)
    }, [state])
    return <div>
        <Button onClick={() => {
            let newState = _.clone(state)
            newState.url += "yolo"

            setState(newState)
        }}>
            modify my state
        </Button>
        {JSON.stringify(state)}
    </div>
}

export const Slicer: React.FC = () => {

    const [gCodeVisualizations, setGCodeVisualizations] = React.useState<Array<{ url?: string, file?: File }>>([])

    /*
    const [forceRender, setForceRender] = React.useState(0)    //include this as a depencency in every useEffect you want to be able to force re-render. this is not the "react" way but very hellpful in performance critical apps like this where you cannot state everything
    const forceReRender = () => {   
        setForceRender(prev => prev + 1)
    }
    */

    // subscribe to incoming change-messages, filter for state id internally
    // @todo: refactor this into websocket manager
    //@ts-ignore




    //const [entityList, setEntityList] = useRecoilState(entityListAtom)

    const [syncedEntityList, setSyncedEntityList] = useRecoilState(getSyncedEntityListAtom({}))

    //const lineSegments = React.useRef<Array<LineSegment>>([])
    const acceptedFilesCallback = async (files: File[]) => {
        //only gets called if there are n>0 accepted files, no zero length check needed
        if (files.length > 1) alert('multiple gcode files not yet supported, only reading first file')

        console.log('adding file to visualizations')
        console.log(files[0])

        /*
        setGCodeVisualizations(prev => {
            const newState = [...prev, {
                file: files[0]
            }]
            console.log(newState)
            return newState
        })


        console.log(gCodeVisualizations)
        */
        //if (_.isString(fileContent)) parseGCodeFromString(fileContent)
    }

    const broadcastIncoming = async (incoming: DataRequestEntityResync) => {

        const connectionState = getRecoil(getConnectionStateAtom({}))
        const syncedEntityList = getRecoil(getSyncedEntityListAtom({}))
        if (!_.isNil(incoming.action) && incoming.action === 'requestEntityResync') {
            // resync entities

            console.log('received command to resync entities')
            const entities = await socketCallAndResponse({
                action: 'invoke',
                invoke: 'requestRoomEntityState',
                data: {
                    roomId: connectionState.roomId
                }
            }) as unknown

            let entitiesCast = entities as Array<SyncedEntity>

            console.log('received entities:')
            console.log(entities)
            console.log(syncedEntityList)

            for (let entity of entitiesCast) {

                const alreadyPresent = _.filter(syncedEntityList, e => e.id === entity.id).length > 0

                // if not already present
                if (!alreadyPresent) {

                    console.log('NEW ENTITY!')


                    setRecoil(getSyncedEntityAtom({ id: entity.id }), entity)

                    setSyncedEntityList(prev => {
                        return [...prev,
                        { id: entity.id }]
                    })
                }
            }
        }
    }

    // load alpacca on startup
    useEffect(() => {
        const uuid = uuidv4()

        //create or retrieve atom

        // standard atom
        /*
        setEntityList(prev => [..._.clone(prev), {
            id: uuid,
            url: '/alpacc.gcode'
        }])
        */

        // do not subscribe
        //@TODO: fix this, make a subscribe to callbacks queue thats independent of the websocket instance!
        //@ts-ignore
        setTimeout(() => { subscribeToIncoming(broadcastIncoming) }, 1000)

        return () => {
            //@ts-ignore
            unsubscribeToIncoming(broadcastIncoming)
        }
    }, [])


    const [uiFromChild, setUiFromChild] = useState<JSX.Element>()

    const drawMyUi = (ui: JSX.Element) => {
        //@todo: re-enable 2d drawing
        //setUiFromChild(ui)
    }

    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

    return <SnackbarProvider>
        <Dropzone style={{ width: '100vw', height: '100vh' }} callback={acceptedFilesCallback} logo={logoGrey} onHoverText="Drop *.gcode or *.stl file here" accept=".gcode,.stl">
            <Box
                component="div"
                //onPointerDown={e => { pointerCoordsRef.current = { x: e.clientX, y: e.clientY } }}
                //onPointerUp={handlePointerUp}
                //onContextMenu={e => { e.preventDefault() }}

                style={{
                    cursor: 'context-menu', background: 'linear-gradient(180deg, #555555 20%, #111111)',
                    height: '100vh'
                }}
            >


                <VRCanvas foveation={0} gl={{}} >
                    <VRInjector>
                        <RecoilBridge>

                            <ambientLight />
                            <pointLight position={[10, 10, 10]} />

                            <DefaultXRControllers />
                            <Hands />
                            {/* is 34/34 now, adjust for printer size later */}
                            <gridHelper receiveShadow args={[8.5, 34, 0x888888]} position={[0, 0, 0]} />

                            {/* @ts-ignore */}
                            <OrbitControls target={[0, 1, 0]} enableDamping dampingFactor={0.1} makeDefault />

                            {/* @ts-ignore  */}
                            <PerspectiveCamera makeDefault position={[-2, 2, -2]} />

                            <DisplayGCodeRecoil key={'yolo'} entity={{ id: 'yolo', url: 'alpacc.gcode' }} drawMyUi={drawMyUi} />

                        </RecoilBridge>
                    </VRInjector>
                </VRCanvas>


                <div style={{ position: 'absolute', top: '0', left: '0' }}>
                    {/* 
                            // just a lot of recoil debugging ignore
                    <Button onClick={() => {
                        setEntityList([...entityList, { id: uuidv4() }])
                    }}>one more entity</Button>
                    <DebugObserver></DebugObserver>
                    {uiFromChild}

                    <div>
                        {
                            _.map(entityList, entity => {
                                return <DebugEntityState key={entity.id} id={entity.id} />
                            })
                        }
                    </div>
                    */}

                </div>

            </Box>
        </Dropzone>
    </SnackbarProvider>
}