import { generateSlug } from "random-word-slugs"
import { atomFamily } from "recoil"
import { WebSocket } from "isomorphic-ws"
import { localhostWebsocketURL, onlineWebsoscketURL } from "../constants"

export interface ConnectionState {
    websocketUrl: string
    username: string
    receiving: boolean
    roomId: string
    socketState: 'connecting' | 'connected' | 'disconnected' | 'syncing' | 'uploading' | 'trying to reconnect' | 'error' | 'receiving'
    usage: 'locally' | 'online' | null
}

export const connectionStateDefaults: ConnectionState = {
    websocketUrl: window.location.origin.indexOf('://localhost') > 0 ? localhostWebsocketURL : onlineWebsoscketURL,
    username: generateSlug(),
    receiving: false,
    roomId: '',
    socketState: 'disconnected',
    usage: null
}

//again using the no default value to get a singleton trick, this will always return the same atom
export const getConnectionStateAtom = atomFamily<ConnectionState, {}>({
    key: 'connectionState',
    default: connectionStateDefaults
})