import produce from 'immer'
import create from 'zustand'
import { ParsedGCode, DataPoint } from '../GCodeParser'
import _ from 'lodash'

export type DisplayMode = {
    id: DisplayModesEnum              //internal display mode id, should be unique
    buttonText: string      //displyed text
    active: boolean         //if display mode is active, this controls button highlighting
    availableFunction: ((parsedGCode: ParsedGCode) => boolean)      //if the display mode is available. for example if you import gcode without ;TYPE flavor, the types view is not available
    available: boolean              //boolean if displaymode is available to be displayed
    autoselectPriority: number      //priority to order which mode to be selected active if new gcode is loaded. seems excessive but is for futureproofing
}

export type DisplayParams = {
    displayModes: Array<DisplayMode>
    displayComments: boolean
    detail: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
    colors: {
        traversal: string
        extrude: string
        flowLow: string
        flowHigh: string
        tempLow: string
        tempHigh: string
    }
}

interface MainStore {
    displayParams: DisplayParams
    setColor: (colorName: string, colorValue: string) => void       //set a single color
    setDisplayParams: (newDisplayParams: DisplayParams) => void
    switchDisplayMode: (displayMode: DisplayMode) => void
    loadedFilename: string                          //name of currently loaded gcode file
    setLoadedFilename: (fileName: string) => void   //
    parsedGCode: ParsedGCode | null          //array of parsed gcode lines written into datapoints, need to be processed further for 3d display
    setParsedGCode: (newGCode: ParsedGCode) => void
}

export enum DisplayModesEnum {
    '3D',
    'HEAT',
    'FLOW',
    'TYPES'
}

export const displayParamsTemplate: DisplayParams = {
    displayModes: [
        {
            id: DisplayModesEnum['3D'],
            buttonText: '3D',
            active: true,
            available: true,
            availableFunction: () => true,
            autoselectPriority: 3
        },
        {
            id: DisplayModesEnum.HEAT,
            buttonText: 'HEAT',
            active: false,
            available: true,
            availableFunction: () => true,
            autoselectPriority: 2
        },
        {
            id: DisplayModesEnum.FLOW,
            buttonText: 'FLOW',
            active: false,
            available: true,
            availableFunction: () => true,
            autoselectPriority: 2
        },
        {
            id: DisplayModesEnum.TYPES,
            buttonText: 'TYPES',
            active: false,
            available: false,
            availableFunction: (parsedGCode: ParsedGCode) => parsedGCode.typeNames.length > 1,
            autoselectPriority: 4
        }
    ],
    detail: 9,
    displayComments: false,
    colors: {
        traversal: 'red',
        extrude: 'green',
        flowLow: '',
        flowHigh: '',
        tempLow: '',
        tempHigh: ''
    }
}


export const useMainStore = create<MainStore>(set => ({
    displayParams: displayParamsTemplate,
    setColor: (colorName, colorValue) => {
        set(produce(draft => { draft.displayMode.colors[colorName] = colorValue }))
    },
    setDisplayParams: newDisplayMode => {
        set(produce(draft => { draft.displayMode = newDisplayMode }))
    },
    switchDisplayMode: (displayMode: DisplayMode) => {
        set(produce(draft => {
            _.forEach(draft.displayParams.displayModes, mode => {
                if (mode.id == displayMode.id) {
                    mode.active = true
                } else {
                    mode.active = false
                }
            })

            // if no mode is active, activate the first one
            if (_.filter(draft.displayParams.displayModes, mode => mode.active).length === 0) {
                draft.displayParams.displayModes[0].active = true
            }
        }))
    },
    loadedFilename: '',
    setLoadedFilename: newFileName => {
        set(produce(draft => { draft.loadedFileName = newFileName }))
    },
    parsedGCode: null,
    setParsedGCode: (newGCode: ParsedGCode) => {
        set(produce((draft: MainStore) => {
            //calculate availability for each display mode if a new parsed gcode is set
            _.forEach(draft.displayParams.displayModes, displayMode => {
                displayMode.available = displayMode.availableFunction(newGCode)

                //set every mode to inactive for the prioritized activation next line
                displayMode.active = false
            })

            // order by priority descending and set the first available mode to active.
            // this  - for example - enables the types mode by default if a model with types is imported
            draft.displayParams.displayModes = _.orderBy(draft.displayParams.displayModes, ['autoselectPriority'], ['desc'])
            let mode = _.find(draft.displayParams.displayModes, displayMode => displayMode.available)

            if (!_.isNil(mode)) mode.active = true

            //assign new gcode
            draft.parsedGCode = newGCode
        }))
    }
}))


