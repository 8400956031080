import WebSocket from 'isomorphic-ws'
import { websocketUrl } from "../constants"
import _ from 'lodash'
import { getRecoil, setRecoil } from "../Recoil/RecoilGlobalManager"
import { getConnectionStateAtom, ConnectionState } from '../Recoil/ConnectionState'
import { WebsocketResponse } from './WebSocketManager'

export type RoomUser = {
    username: string
    connectionId: string
}







export type SocketCallback = {
    f: (response: WebsocketResponse) => void,
    id?: string
}


export class WebSocketSingleton {
    private static instance: WebSocketSingleton

    public socket: WebSocket

    private constructor() {
        this.socket = new WebSocket(websocketUrl)

        this.socket.onmessage = this.msgReceivedCallback
        this.onMessageCallbacks = []
    }

    private onMessageCallbacks: Array<SocketCallback>


    public addCallbackBroadcast = (f: (response: WebsocketResponse) => void) => {
        this.onMessageCallbacks.push({ f: f })
    }

    public addCallback = (newCb: { id: string, f: (response: WebsocketResponse) => void }) => {
        this.onMessageCallbacks.push(newCb)
    }

    public removeCallback = (oldCb: (response: WebsocketResponse) => void) => {
        this.onMessageCallbacks = _.filter(this.onMessageCallbacks, cb => cb.f !== oldCb)
    }


    private msgReceivedCallback = (msg: WebSocket.MessageEvent) => {
        setSocketState({ receiving: true }) // set connection state led to green
        returnState()       // set the led to transparent again


        const obj = JSON.parse(msg.data.toString()) as WebsocketResponse

        for (let cb of this.onMessageCallbacks) {

            // if neither callback nor message has an id, dispatch to all callbacks
            if (_.isNil(cb.id) || _.isNil(obj.id)) {
                cb.f(obj)
            }
            else if (cb.id === obj.id) {
                cb.f(obj)

                this.removeCallback(cb.f)
            }
        }
    }


    public static getInstance(): Promise<WebSocketSingleton> {
        return new Promise((resolve, reject) => {

            if (!WebSocketSingleton.instance || WebSocketSingleton.instance.socket.readyState === WebSocketSingleton.instance.socket.CLOSED) {
                //setSocketState({ socketState: 'connecting' })

                console.info('trying to connect to ' + websocketUrl)

                WebSocketSingleton.instance = new WebSocketSingleton()

                WebSocketSingleton.instance.socket.onopen = () => {
                    //setSocketState({ socketState: 'connected' })
                    resolve(WebSocketSingleton.instance)
                }

                WebSocketSingleton.instance.socket.onerror = (e) => {
                    //setSocketState({ socketState: 'error' })
                    reject()
                }
            } else {
                resolve(WebSocketSingleton.instance)
            }
        })

    }
}

const returnState = _.debounce(() => {
    setSocketState({ receiving: false })
}, 100)


const setSocketState = (newSocketState: Partial<ConnectionState>) => {
    setRecoil(getConnectionStateAtom({}), (prev: ConnectionState): ConnectionState => {
        return {
            ...prev,
            ...newSocketState
        }
    })
}