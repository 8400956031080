import { useXR } from "@react-three/xr"
import React, { useEffect, useState } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'

interface VRInjector {
}

/**
 * this class shimmies itself between the content-components and the VR canvas to give access to all the VR specific 
 * hooks and features
 * @param param0 V
 * @returns 
 */
export const VRInjector: React.FC<VRInjector> = ({ children }) => {

    const {
        gl, // WebGL renderer
        scene, // Default scene
        camera, // Default camera
        raycaster, // Default raycaster
        size, // Bounds of the view (which stretches 100% and auto-adjusts)
        viewport, // Bounds of the viewport in 3d units + factor (size/viewport)
        mouse, // Current, centered, normalized 2D mouse coordinates
        clock, // THREE.Clock (useful for useFrame deltas)
        invalidate, // Invalidates a single frame (for <Canvas invalidateFrameloop />)
    } = useThree()


    const { controllers, player, isPresenting } = useXR()

    useEffect(() => {
        gl.xr.setFoveation(0)
        //gl.xr.setFramebufferScaleFactor(0.5)

    }, [gl])

    useEffect(() => {
        console.log('isPresenting: ' + isPresenting)

        //set vr player position on entering vr
        if (isPresenting) {
            player.position.z = 1

            player.rotation.setFromQuaternion(new THREE.Quaternion())
            gl.xr.enabled = true
        }

        if (!isPresenting) {
            player.position.z = 0

            gl.xr.enabled = false
            player.rotation.setFromQuaternion(new THREE.Quaternion())
        }

    }, [isPresenting])

    return <>{children}</>


}