/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, Text } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { useFrame } from '@react-three/fiber'
import _ from 'lodash'
type GLTFResult = GLTF & {
  nodes: {
    plasmicslogopart3: THREE.Mesh
    plasmicslogopart2: THREE.Mesh
    plasmicslogopart1: THREE.Mesh
  }
  materials: {}
}

export default function PlasmicsLogo({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/plasmicslogo.gltf') as GLTFResult

  useFrame(({ clock }) => {

    if (_.isNil(group.current)) return
    group.current.rotation.y = clock.getElapsedTime()
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.plasmicslogopart3.geometry}
        material={nodes.plasmicslogopart3.material}
        position={[0.61, 1.03, 0]}
        rotation={[0, 0, 2.08]}
      />
      <mesh geometry={nodes.plasmicslogopart2.geometry} material={nodes.plasmicslogopart2.material} />
      <mesh
        geometry={nodes.plasmicslogopart1.geometry}
        material={nodes.plasmicslogopart1.material}
        position={[-0.63, 1.07, 0]}
        rotation={[0, 0, -2.1]}
      />

      <Text
        scale={[10, 10, 10]}
        color="white" // default
        anchorX="center" // default
        anchorY="top" // default
        position={[0, 3.5, 0]}
      >
        loading
      </Text>
    </group>
  )
}

useGLTF.preload('/plasmicslogo.gltf')
