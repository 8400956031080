import { RecoilRoot, atom, atomFamily, useSetRecoilState } from 'recoil'
import _, { debounce } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import validUrl from 'valid-url'
import { localhostWebsocketURL, onlineWebsoscketURL } from '../constants'
import { generateSlug } from 'random-word-slugs'

export interface Entity {
    id: string
    url?: string
    file?: File
}

//get new entity atom with given id as default id
export const getEntityAtom = atomFamily<Entity, { id: string }>({
    key: 'entityFamily',
    default: ({ id }) => {
        return {
            id: id
        }
    }
})

// use an atom to store a list of entities
export const entityListAtom = atom<Array<Entity>>({
    key: 'entityList',
    default: []
})